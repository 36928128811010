<template>
  <el-menu :default-active="activeIndex" router class="el-menu-vertical-demo ptb-32" background-color="#FFFFFF"
    text-color="#8490AD" active-text-color="#2974EF">
    <el-menu-item :class="[$isPc?'justify-center':'justify-between', 'align-center']" v-for="(nav,index) in navs"
      :key="index" :index="nav.route_url" :route="nav.route_url"
      v-show="($isPc?!nav.isWap:true) && ($store.getters.userToken?true:!nav.watchLogin)" @click="changeNav(nav)">
      <span> {{nav.name}}</span>
      <i class="el-icon-arrow-right"></i>
    </el-menu-item>
  </el-menu>
</template>
<script>
import { setItem, getItem } from '@/utils/storage'
import { logout } from '@/api/account'
export default {
  name: 'MyMenu',
  data () {
    return {
      activeIndex: getItem('router_url', 'sessionStorage') || this.$route.path,
      navs: [
        { name: '我的申请', route_url: '/my/order', watchLogin: true },
        { name: '我的收藏', route_url: '/my/collect', watchLogin: true },
        { name: this.$isPc ? '个人中心' : '账号管理', route_url: '/my/info', watchLogin: true },
        { name: '关于我们', route_url: '/about_us', isWap: true, watchLogin: false },
        { name: '联系我们', route_url: '/counsel', isWap: true, watchLogin: false },
        { name: '退出登录', event: 'logout', isWap: true, watchLogin: true },
      ]
    };
  },
  watch: {
    $route (to, from) {
      if (!to.meta.isDetails) {
        setItem('router_url', to.path, 'sessionStorage')
      }
      this.activeIndex = to.meta.isDetails ? getItem('router_url', 'sessionStorage') : to.path
    },
  },
  methods: {
    changeNav (nav) {
      if (nav.event == 'logout') {
        this.logout()
      }
    },
    async logout () {
      await logout()
      this.$store.dispatch('setUserToken', '')
    },
  },
}
</script>