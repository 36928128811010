/** 个人中心 */
<template>
  <!-- PC端 -->
  <el-container v-if="$isPc" class="my content">
    <el-aside :width="$isPc?'200px':'100%'" class="personal-navs mt-20 pb-50">
      <MyMenu />
    </el-aside>
    <el-main class="my-main">
      <keep-alive>
        <router-view />
      </keep-alive>
    </el-main>
  </el-container>

  <!-- 移动端 -->
  <div class="my-wap" v-else-if="!$isPc && $route.name=='My'">
    <div class="user-info back-fff mb-20">
      <template v-if="userToken">
        <el-avatar class="mb-10" :src="require('@/assets/images/avatar.png')"></el-avatar>
        <UpdateName />
      </template>
      <template v-else>
        <el-avatar class="mb-10" :src="require('@/assets/images/avatar_default.png')"> </el-avatar>
        <el-button type="primary" size="mini">
          <router-link to="/account/login">请登录</router-link>
        </el-button>
      </template>
    </div>
    <MyMenu />
  </div>

  <keep-alive v-else>
    <router-view />
  </keep-alive>

</template>

<script>
import { mapGetters } from 'vuex'
import MyMenu from './menu.vue'
import UpdateName from './components/updateName.vue'
export default {
  name: 'My',
  components: {
    MyMenu,
    UpdateName
  },
  computed: {
    ...mapGetters([
      'userToken',
      'userInfo'
    ])
  }
}
</script>
<style lang="scss" scoped>
.el-container {
  .my-main {
    padding: 20px 50px;
  }
}
.my-wap {
  min-height: 100%;
  background-color: #f4f6fa;
  .user-info {
    text-align: center;
    padding: 50px 0;
  }
  /deep/.el-menu .el-menu-item {
    text-align: left;
    border-bottom: 1px solid #e3e3e3;
  }
  .el-button {
    display: block;
    margin: 0 auto;
    a {
      color: #fff;
    }
  }
}
</style>