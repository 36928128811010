/** 修改用户名 */
<template>

  <div class="user-con align-center">
    <el-form :model="form" :rules="rules" ref="form" @submit.native.prevent>
      <el-form-item prop="name">
        <el-input v-model="form.name" :class="{'is-active':!readonly}" id="user-name" size="small"
          @blur="updateName('form')" @keyup.enter.native="updateName('form')" :readonly="readonly"></el-input>
      </el-form-item>
    </el-form>

    <el-button type="text" :class="{'ml-20':$isPc}" @click="readonly=false">
      <label for="user-name">
        <i class="el-icon-edit"></i>
      </label>
    </el-button>

  </div>
</template>
<script>
import { updateinfo } from '@/api/account'
import { mapGetters } from 'vuex'
export default {
  name: 'UpdateName',
  data () {
    // 用户名
    var validateName = (rule, value, callback) => {
      const pass = new RegExp('^[\u0391-\uFFE5A-Za-z]+$')
      if (value == '') {
        callback()
      } else if (!pass.test(value)) {
        return callback(new Error('请输入2~6位字母或汉字'))
      } else {
        callback()
      }
    };
    return {
      readonly: true,
      form: {
        name: '',
      },
      rules: {
        name: [
          { min: 2, max: 6, message: '请输入2~6位字母或汉字' },
          { validator: validateName, trigger: ['blur', 'change'] }
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  mounted () {
    this.form.name = this.userInfo.username
  },
  methods: {
    async updateName (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.readonly = true
          if (this.form.name && this.form.name != this.userInfo.username) {
            try {
              let old_userInfo = this.$store.getters.userInfo
              let { code, msg } = await updateinfo(this.form)
              if (code == 200) {
                this.$message.success(msg);
                old_userInfo.username = this.form.name
                this.$store.dispatch('setUserInfo', old_userInfo)
              } else {
                this.$message.error(msg)
              }
            } catch (error) { }
          } else {
            this.form.name = this.userInfo.username
          }
        }
      });
    }
  },
}
</script>
<style lang="scss" scoped>
/deep/.el-form {
  .el-form-item {
    margin-bottom: 0;
    .el-input__inner {
      max-width: 98px;
      padding: 0;
      border: 0;
      border-radius: 0;
      font-size: 16px;
      color: #333;
      text-align: right;
    }
    .is-active {
      .el-input__inner {
        border-bottom: 1px solid #606266;
      }
    }
  }
}
@media (max-width: 767px) {
  .user-con {
    justify-content: center;
  }
  /deep/.el-form {
    .el-form-item {
      .el-input__inner {
        text-align: center;
      }
    }
  }
}
</style>